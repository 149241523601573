import "./App.css";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-scroll";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import lights from "./images/lights.jpg";

import projects from "./projects.json";
import { Github, Linkedin, Envelope, PlayFill } from "react-bootstrap-icons";

import { FaNodeJs, FaReact, FaHtml5, FaCss3Alt } from "react-icons/fa";
import {
  SiJavascript,
  SiCsharp,
  SiPostgresql,
  SiMongodb,
} from "react-icons/si";
import { DiMysql } from "react-icons/di";

function App() {
  const skills = [
    { icon: <SiJavascript size={64} />, name: "JavaScript" },
    { icon: <FaNodeJs size={64} />, name: "Node.js" },
    { icon: <FaReact size={64} />, name: "React" },
    { icon: <SiCsharp size={64} />, name: "C#" },
    { icon: <SiPostgresql size={64} />, name: "PostgreSQL" },
    { icon: <DiMysql size={64} />, name: "MySQL" },
    { icon: <SiMongodb size={64} />, name: "MongoDB" },
    { icon: <FaCss3Alt size={64} />, name: "CSS" },
    { icon: <FaHtml5 size={64} />, name: "HTML" },
  ];
  return (
    <div className="App">
      <div className="banner" style={{ backgroundImage: `url(${lights})` }}>
        <div className="banner-overlay">
          <Navbar expand="lg" className="navbar" variant="dark">
            <Container>
              <div className="navbar-brand">Tyler Kitt</div>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className="justify-content-end navbar-collapse"
              >
                <Nav className="ml-auto">
                  <Link
                    activeClass="active"
                    to="about"
                    spy={true}
                    smooth={true}
                    duration={500}
                    className="nav-link"
                  >
                    About
                  </Link>
                  <Link
                    activeClass="active"
                    to="skills"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="nav-link"
                  >
                    Skills
                  </Link>
                  <Link
                    activeClass="active"
                    to="projects"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="nav-link"
                  >
                    Projects
                  </Link>
                  <Link
                    activeClass="active"
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="nav-link"
                  >
                    Contact
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <div className="banner-content">
            <h1 className="banner-title">Welcome to my portfolio</h1>
          </div>
        </div>
      </div>
      <section id="about">
        <Container className="mt-3">
          <h1 className="mb-4">About Me</h1>
          <p>
            Hello! I'm Tyler Kitt, a passionate web developer with a knack for
            creating dynamic and user-friendly websites and applications. I
            honed my skills at Bethel School of Technology, where I completed an
            intensive one-year coding bootcamp, specializing in full-stack web
            development.
          </p>
          <p>
            During my training, I mastered various technologies and tools that
            have equipped me to handle both front-end and back-end development
            using frameworks like React and Node.
          </p>
          <h3>What I do</h3>
          <p>
            I thrive on building seamless and efficient web solutions. Whether
            it's designing the interface or developing the server-side logic, I
            aim to deliver high-quality and scalable applications. My goal is
            always to enhance user experience while ensuring robust performance.
          </p>
          <h3>Beyond Coding</h3>
          <p>
            When I'm not coding, you can find me indulging in my favorite
            hobbies. I enjoy the strategic depth and challenge of chess, and I'm
            also passionate about capturing moments through photography. These
            interests not only provide a creative outlet but also inspire my
            approach to problem-solving and design in web development.
          </p>
          <p>
            I'm always excited to take on new challenges and collaborate on
            innovative projects. Let's build something amazing together!
          </p>
        </Container>
      </section>
      <section id="skills">
        <Container className="mt-3">
          <Row>
            <h1 className="mb-5">My Skills</h1>
            {skills.map((skill, index) => (
              <Col
                sm={12}
                md={6}
                lg={4}
                key={index}
                className="text-center mb-4"
              >
                <div className="d-flex justify-content-center">
                  {skill.icon}
                </div>
                <p>{skill.name}</p>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section id="projects">
        <Container className="mt-3">
          <h1 className="mb-4">My Projects</h1>
          <Row>
            {projects.map((project) => (
              <Col sm={12} md={6} lg={4} key={project.id}>
                <div className="d-flex justify-content-center">
                  <div className="Project">
                    <Card className="project-card">
                      <h2 style={{ color: "white" }}>{project.name}</h2>
                      <Card.Body className="project-card-body">
                        {project.description}
                      </Card.Body>
                      <div className="mb-3 d-flex justify-content-around">
                        <Button
                          href={project.liveLink}
                          className="project-card-button mr-2"
                        >
                          <PlayFill size={32} />
                        </Button>
                        <Button
                          href={project.githubRepo}
                          className="project-card-button ml-2"
                        >
                          <Github size={32} />
                        </Button>
                      </div>
                    </Card>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section id="contact">
        <Container className="mt-3">
          <Card className="text-center contact-card mb-3">
            <Card.Header>
              <h2 style={{ color: "white" }}>Contact Me</h2>
            </Card.Header>
            <Card.Body>
              <Card.Title>Tyler Kittivanichkulkrai</Card.Title>
              <Card.Text>
                <p>Email: tylerkitt4@gmail.com</p>
                <p>Phone: +1(217) 369-5444</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Container>
      </section>
      <footer className="MyFooter">
        <div className="container">
          <ul className="footer-links">
            <li>
              <a
                href="https://github.com/TKKitt"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Github size={32} />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/tyler-kitt"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Linkedin size={32} />
              </a>
            </li>
            <li>
              <a href="mailto:tylerkitt4@gmail.com">
                <Envelope size={32} />
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
}

export default App;
